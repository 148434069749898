import React from 'react'
import { GetStaticProps, InferGetStaticPropsType } from 'next'
import useAccordion from '../hooks/useAccordion'
import {
  getLogoList,
  getLogosList,
  getNewsList,
  getVoice,
  newtClient,
} from '@/utils/newt/newt'

import { Request } from '@/components/non-block/templates/Request'
import { Functions } from '@/components/non-block/templates/Functions'
import { Security } from '@/components/non-block/templates/Security'
import { Question } from '@/components/non-block/templates/Question'
import { NewsList } from '@/components/newt/templates/NewsList'
import { Contact } from '../components/parts/contact'
import { Logo } from '@/components/non-block/templates/Logo'
import { VoiceList } from '@/components/newt/templates/VoiceList'
import Link from 'next/link'
import { Reason } from '@/components/non-block/templates/Reason'
import { Solution } from '@/components/non-block/templates/Solution'
import { Resolve } from '@/components/non-block/templates/Resolve'
import { Issue } from '@/components/non-block/templates/Issue'
import { Video } from '@/components/non-block/templates/Video'
import { Visual } from '@/components/non-block/templates/Visual'
import { NextSeo } from 'next-seo'

const Index = ({
  news,
  logo,
  voice,
  logos,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  useAccordion()

  return (
    <>
      <NextSeo
        title={`LAWGUE - Cloud Document Workspace`}
        description="LAWGUEは、文書作成をあらゆる面からサポート・効率化するAI を搭載したクラウド ドキュメント ワークスペースです。"
        canonical={`https://lawgue.com/news/`}
        openGraph={{
          title: `LAWGUE - Cloud Document Workspace`,
          description:
            'LAWGUEは、文書作成をあらゆる面からサポート・効率化するAI を搭載したクラウド ドキュメント ワークスペースです。',
          url: 'https://lawgue.com/',
          images: [{ url: 'https://lawgue.com/OGP.jpg' }],
          type: 'website',
          siteName: 'LAWGUE - Cloud Document Workspace',
        }}
      />

      <Visual logos={logos} />
      <Video />
      <Issue />
      <Resolve />
      <Request />
      <Solution />
      <Reason />

      <section className="text-center mx-auto pt-40 pb-40 maxsm:pb-10 maxsm:pt-10 sec_inner_w60 maxsm:w-full maxsm:px-6">
        <h2 className="text-center maxsm:text-center text-4xl maxsm:text-lg text-textblack font-bold fontfeaturesettings">
          導入事例
        </h2>
        <ul className="flex flex-col sm:grid sm:grid-cols-3 gap-y-[30px] gap-x-[60px] mt-[24px] sm:mt-[40px] transition duration-0 ease-in text-left">
          {voice.map((voiceItem) => (
            <VoiceList key={voiceItem._id} voice={voiceItem} />
          ))}
        </ul>
        <Link
          href="/voice"
          className="block base_button linkbutton__medium button__hidden undefined transition hover:text-secondary hover:border-secondary mt-16 maxsm:mt-8"
        >
          もっと見る
        </Link>
      </section>

      <section className="text-center mx-auto pt-40 pb-44 bg-white maxsm:w-full maxsm:pb-20 maxsm:pt-10 maxsm:px-6">
        <h2 className="title text-center text-textblack text-base md:text-2xl font-bold">
          他にも、数多くの企業様にご利用いただいています
        </h2>
        <div className="mt-16 maxsm:mt-8">
          <div className="m-auto max-w-[1176px] flex flex-wrap justify-start items-center gap-x-[3.9%] gap-y-[40px] sm:gap-y-[42px] px-[0.25rem] sm:px-[3rem]">
            {logo.map((logoItem) => (
              <Logo key={logoItem._id} logo={logoItem} />
            ))}
          </div>
        </div>
      </section>

      <Request />
      <Functions />
      <Security />
      <Question />

      <section className="text-center mx-auto pt-40 pb-40 maxsm:pb-10 maxsm:pt-10 sec_inner_w60 maxsm:w-full maxsm:px-6">
        <h2 className="text-center maxsm:text-center text-4xl maxsm:text-lg text-textblack font-bold fontfeaturesettings">
          お知らせ
        </h2>
        <div className="mt-12 maxsm:mt-4">
          {news.map((newsItem) => (
            <NewsList key={newsItem._id} news={newsItem} />
          ))}
        </div>
      </section>

      <Contact bg="bg-lawguebase" />
    </>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const [{ news }, { logo }, { logos }, { voice }] = await Promise.all([
    getNewsList({ client: newtClient('cdn'), query: { limit: 10 } }),
    getLogoList({
      client: newtClient('cdn'),
      query: { order: ['-_sys.customOrder'] },
    }),
    getLogosList({
      client: newtClient('cdn'),
      query: { order: ['-_sys.customOrder'] },
    }),
    getVoice({
      client: newtClient('cdn'),
      query: { limit: 3, order: ['-_sys.customOrder'] },
    }),
  ])

  return {
    props: {
      news,
      voice,
      logo,
      logos,
    },
  }
}

export default Index
